.detailsWrap {
    width: 400px;
    overflow: auto;
    top: 50px;
    left: 50px;
    position: absolute;
    z-index: 99999;
    background: linear-gradient(180deg, #CEFFF4 0%, rgba(206, 255, 244, 0.00));
    background-color: rgba(241, 239, 239, 0.8);
    overflow: hidden;
    padding: 30px;
    border-radius: 15px;

    &:hover {
        background-color: rgba(241, 239, 239, 1);
        cursor: pointer;
    }


    @media screen and (max-width: 768px) {
        width: 100%;
        position: static;
        bottom: 0;
        left: 0;
        top: auto;
        height: fit-content;
        padding: 15px;
        background-color: rgba(241, 239, 239, 1);
    }
}

.header, .singleHeader {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
    color: #171B1F;
}

.singleHeader {
    margin-bottom: 15px;
}

.subHeader {
    color: #3e4958;
    font-size: 14px;
}

.textWrap {
    width: 70%;
}

.timeLeft {
    background-color: #FFF;
    aspect-ratio: 1;
    border-radius: 15px;
    text-align: center;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .time {
        font-size: 24px;
        font-weight: 700;
        line-height: 100%;
    }

    .minLabel {
        font-size: 12px;
        line-height: 100%;
    }
}

.headerNotPickedUp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.carDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .modelAndPlates {
        .model {
            font-size: 14px;
            text-align: right;
            color: #171B1F;

        }

        .plates {
            text-align: right;
            color: #171B1F;

        }
    }
}

.imagesWrap {
    display: flex;
    position: relative;

    img {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        border: 1px solid #E9E9E9;
    }

    .driverImage {
        position: relative;
        z-index: 9;
        object-fit: cover;
    }

    .carImage {
        position: relative;
        left: -10px;
        object-fit: cover;
    }
}

.locationSegment {
    background-color: #fff;
    border-radius: 15px;

    .startAddress,
    .endAddress {
        padding: 15px 0px;
        margin: 0 20px;
        color: #171B1F;
        display: flex;
        align-items: center;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        font-weight: 500;

    }

    .startAddress {
        border-bottom: 1px solid #E9E9E9;
    }
}

.locationImages {
    height: 18px;
    margin-right: 5px;
}

.driverNameAndPhone {
    margin: 20px 0;
    flex-wrap: wrap;

    .driverName {
        color: #171B1F;
        font-size: 14px;
        margin: 5px 0;
        display: flex;
        align-items: center;
    }

    .driverInfoIcons {
        margin-left: 10px;
    }

    .driverPhone {
        color: #00A27F;
        font-weight: 700;
        font-size: 16px;
        margin: 5px 0;
    }
}

.ratingAndDriveCount {
    display: inline-flex;
    align-items: center;
}