.navigationBar {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 80px;
    width: 100%;
    background-color: #171b1f;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}

.errorPageWrap {
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat !important;
    background-position: bottom right !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;


    .content,
    .errorContent {
        max-width: 600px;
        margin: 0 30px;

        @media screen and (max-width: 768px) {
            margin: 0px 40px;
        }

        .header {
            font-size: 24px;
            font-weight: 700;
            text-align: center;

            @media screen and (max-width: 768px) {
                font-size: 18px;
            }
        }

        .description {
            color: #3E4958;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 136%;
            letter-spacing: 0.1px;
            margin: 15px 0;
            text-align: center;
        }


        .storesWrap {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
        }

        .appStore {
            width: 160px;
            // height: 36px;
        }
    }
}