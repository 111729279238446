.mapWrap {
    height: 100vh;
    width: 100vw;

    @media screen and (max-width: 768px) {
        height: 60vh;
    }
}


.itineraryContainer {
    display: none;
}

.driverMarker {
    transition: all 1s ease-in-out;
}
.markerr {
    transition: all 1s ease-in-out;
    height: 100%;
    width: 100%;
}